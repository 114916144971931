import { useForm, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { BUILDING_TYPE_ID } from '../utils/helpers/constants';
import { setCategoryIds } from '../actions';

export function useBuildingType() {
  const { values } = useFormState();
  const { change, submit } = useForm();
  const dispatch = useDispatch();

  const categoryIds = useSelector(state => state.searchForm.categoryIds);

  function updateCategoryIds(initialResult = [], id) {
    let result = initialResult.slice();
    if (result.includes(id)) {
      result = result.filter(prevId => prevId !== id);
    } else {
      result.push(id);
    }
    const isSelectedAll = !result.length || result.length > 1;
    change(
      'category_id',
      isSelectedAll ? BUILDING_TYPE_ID.FLAT_ALL : result[0]
    );
    return result;
  }

  function handleBuildingType(id) {
    const result = updateCategoryIds(categoryIds, id);
    submit();
    dispatch(setCategoryIds(result));
  }

  useEffect(() => {
    if (values.category_id !== BUILDING_TYPE_ID.FLAT_ALL) {
      dispatch(setCategoryIds([values.category_id]));
    }
  }, []);

  return { handleBuildingType, updateCategoryIds, categoryIds };
}
