import styled, { css } from 'styled-components';
import { SORT_MODAL_HEIGHT } from '../../../../utils/helpers/constants';
import { getPageBottomPosition } from '../../../../utils/helpers/getPageBottomPosition';

export const Box = styled.div`
  left: 0;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;

  ${({ $isSortModal }) =>
    $isSortModal
      ? css`
          top: ${() => {
            const bottomPosition = getPageBottomPosition();
            return `${bottomPosition - SORT_MODAL_HEIGHT}px`;
          }};
        `
      : css`
          bottom: 0;
        `}
`;
