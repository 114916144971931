import React from 'react';

const ItemCardPlaceholder = () => {
  return (
    <div className="placeholder-card color-white">
      <div className="placeholder-card__img animation" />
      <div className="placeholder-row placeholder-row__item-card placeholder-card__city animation" />
      <div className="placeholder-row placeholder-row__item-card placeholder-card__rooms animation" />
      <div className="placeholder-row placeholder-row__item-card placeholder-card__date animation" />
    </div>
  );
};

export default ItemCardPlaceholder;
