import React, { Component } from 'react';
import classNames from 'classnames';
import { withApollo } from '@apollo/client/react/hoc';
import PropTypes from 'prop-types';
import { createBookmarkQuery, deleteBookmarkQuery } from '../../queries';
import Bugsnag from '../../utils/bugsnag';

class Bookmark extends Component {
  constructor(...props) {
    super(...props);
    this.state = { isActive: this.props.isActive };
  }

  static propTypes = {
    itemId: PropTypes.string.isRequired,
    isActive: PropTypes.bool,
    isCurrentItemPage: PropTypes.bool,
    removeItemFunc: PropTypes.func,
    addBookmark: PropTypes.func,
    deleteBookmark: PropTypes.func,
    client: PropTypes.object,
    additionalClasses: PropTypes.string,
    isGalleryHeader: PropTypes.bool,
    setFavorited: PropTypes.func
  };

  addBookmark = itemId => {
    const { mutate } = this.props.client;

    mutate({
      variables: {
        itemId
      },
      mutation: createBookmarkQuery
    }).catch(error => {
      Bugsnag.notify(error);
    });
  };

  deleteBookmark = itemId => {
    const { mutate } = this.props.client;

    mutate({
      variables: {
        itemId
      },
      mutation: deleteBookmarkQuery
    })
      .then(() => {
        this.props.client.cache.modify({
          fields: {
            bookmarks(existing, { readField }) {
              return existing.filter(
                item => readField('id', item.item) !== itemId
              );
            }
          },
          broadcast: true
        });
      })
      .catch(error => {
        Bugsnag.notify(error);
      });
  };

  toggleBookmark = e => {
    const { isActive } = this.state;
    const { itemId } = this.props;
    const action = !isActive ? this.addBookmark : this.deleteBookmark;

    e.preventDefault();
    if (this.props.setFavorited) {
      this.props.setFavorited(!isActive);
    }

    this.setState(previousState => {
      action(itemId);
      return {
        isActive: !previousState.isActive
      };
    });
  };

  componentDidUpdate(prevProps) {
    if (prevProps?.isActive !== this.props.isActive) {
      this.setState(() => {
        return {
          isActive: this.props.isActive
        };
      });
    }
  }

  render() {
    const { itemId, isCurrentItemPage, additionalClasses, isGalleryHeader } =
      this.props;

    const { isActive } = this.state;

    const getFavoriteIcon = () => {
      switch (true) {
        case isGalleryHeader:
          return 'bordered-favorite-buttons';
        case isCurrentItemPage:
          return 'new-favorite-buttons';
        default:
          return 'old-favorite-buttons';
      }
    };
    const favoriteIcon = getFavoriteIcon();

    return (
      <div
        className={classNames(
          'bookmarking',
          'card_view',
          { added: isActive },
          favoriteIcon,
          additionalClasses
        )}
      >
        <a
          data-disable="true"
          className={classNames(isActive ? 'remove_bookmark' : 'add_bookmark')}
          data-cy="bookmark-icon"
          data-remote="true"
          data-method="post"
          href={`/bookmarks?card_view=true&amp;item_id=${itemId}`}
          onClick={this.toggleBookmark}
        />
      </div>
    );
  }
}

Bookmark.defaultProps = {
  isActive: false,
  isCurrentItemPage: false,
  isGalleryHeader: false
};

export default withApollo(Bookmark);
