import { useForm, useFormState } from 'react-final-form';
import { useEffect, useRef, useState } from 'react';

export const useWithNumberField = (fieldNameFrom, fieldNameTo, hide) => {
  const { change, submit } = useForm();
  const { values } = useFormState();

  const fromRef = useRef(null);

  const [from, setFrom] = useState(values[fieldNameFrom] || '');
  const [to, setTo] = useState(values[fieldNameTo] || '');

  const canReset = from || to;

  const onReset = () => {
    setFrom('');
    setTo('');
    change(fieldNameFrom, '');
    change(fieldNameTo, '');
    submit();
    hide();
  };

  const onSubmit = () => {
    change(fieldNameFrom, from);
    change(fieldNameTo, to);
    submit();
    hide();
  };

  useEffect(() => {
    fromRef.current?.focus();
  }, []);

  return {
    from,
    setFrom,
    to,
    setTo,
    onSubmit,
    fromRef,
    values,
    canReset,
    onReset
  };
};
