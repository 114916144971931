import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SectionPlaceholder = ({
  children,
  noHeader = false,
  column = false,
  white = true,
  more = true
}) => {
  const sectionHeader = (
    <div className="placeholder-section__header">
      <div className="placeholder-row placeholder-section__header-title animation" />
      {more && (
        <div className="placeholder-row placeholder-section__header-more animation" />
      )}
    </div>
  );
  const allClassNames = classNames('placeholder-section', {
    'color-white': white
  });
  const listClassNames = classNames(
    'placeholder-section__card-list',
    column && 'placeholder-section__card-list-column'
  );

  return (
    <div className={allClassNames}>
      {!noHeader && sectionHeader}
      <div className={listClassNames}>{children}</div>
    </div>
  );
};

SectionPlaceholder.propTypes = {
  children: PropTypes.node,
  noHeader: PropTypes.bool,
  column: PropTypes.bool,
  white: PropTypes.bool,
  more: PropTypes.bool
};

export default SectionPlaceholder;
