import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const SectionList = props => {
  const { listAdditionalClasses, children } = props;
  const listClasses = classNames('section-list', listAdditionalClasses);
  const refList = props.forwardedRef || null;

  return (
    <div className={listClasses} ref={refList}>
      {children}
    </div>
  );
};

SectionList.propTypes = {
  children: PropTypes.node,
  forwardedRef: PropTypes.object,
  listAdditionalClasses: PropTypes.string
};

SectionList.defaultProps = {
  emptyLayout: null
};

export default SectionList;
